
import React, {useState}  from 'react'
import MakeThumbnailsIcon from '@material-ui/icons/PhotoSizeSelectSmallRounded';
import Tooltip from '@material-ui/core/Tooltip';
import axiosGet from './axiosGet'

const butColor ='#AAA' 

/* Note: if there is many images that shall be compressed this function will timeout. Therefore repeat pushing this button
until zero images are left to compress. Then the button turns white. Libraries with many photos require many repeated pushes */ 
const Function = ({rootdir, subdir}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
  const [buttonColor, setButtonColor] = useState(butColor)
  const [opacity, setOpacity] = useState(1.0)
  const styles = {
    button:{width:45, height:45, padding:0, border:0, opacity}
  }
  const makeThumbnails = () => {
  const url = apiBaseUrl + '/createThumbnails?rootdir=' + rootdir + (subdir?'&subdir=' + subdir:'')
  console.log('makeThumbnails: url:', url)
  setButtonColor('yellow')
  axiosGet(url, data => {
        if (data === null) {
          setButtonColor('red')
        } else if (data.result.length===0) {
          setOpacity(0.3)
          setButtonColor(butColor)
        } else {
          setOpacity(1.0)
          setButtonColor(butColor)
        }
    })
  }
  
  return(
    <Tooltip title={opacity===1.0?'Repeat pushing the button is grey':'All images has thumbnails'} 
        placement={'bottom'}>
      <MakeThumbnailsIcon 
        className="column" 
        data-tooltip="Tooltip Text"
        onClick={makeThumbnails} 
        style={{...styles.button, color:buttonColor}}
      />
    </Tooltip>
  )
}          

export default Function
    