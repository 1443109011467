
import React, {useState}  from 'react'
import AddPhotoMultiple from "./AddPhotoMultiple"

const ButtonAddPhotoMultiple = props => {
  const [buttonColor, setButtonColor] = useState('red')
  const styles = {
    button:{width:45, height:45, padding:0, border:0}
  }
  return(
      <AddPhotoMultiple {...props} setButtonColor={setButtonColor} style={{...styles.button, color:buttonColor}} />
  )
}          
// <img className='column' src={it.src} height={160} alt={it.src}/>
export default ButtonAddPhotoMultiple
    