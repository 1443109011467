import React, {useState, useEffect}  from 'react'
import { connect } from 'react-redux';
import axios from 'axios'
import { NAVBAR_KEY_ROOTDIR, NAVBAR_KEY_SUBDIR, NAVBAR_KEY_ORDER_BY, ORDER_BY_MDATE_ASC, ORDER_BY_MDATE_DESC} from '../../state/navbar';
import { GridListTile } from '@material-ui/core';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL


const image = (im, dirname) => ({
    dir:dirname,
    src:dirname + '/' + im.fname, 
    fname:im.fname, 
    thumbSrc:im.thumbFname?(dirname + '/' + im.thumbFname):undefined,
    thumbFname:im.thumbFname?im.thumbFname:undefined, 
    mdate:im.mdate?im.mdate:undefined, 
})

const directoryName = (rootdir, subdir) => {
    return process.env.REACT_APP_API_BASE_URL + '/images' + ((rootdir && rootdir !=='.') ?'/' + rootdir:'') + (subdir?'/' + subdir:'') 
}

const LayoutImages = ({rootdir, subdir, orderBy, children}) => {
    // const subdirSingleSlash=subdir?subdir.charAt(0)==='/'?'':'/' + subdir:('/images')
    const [list, setList] = useState([])
    const [navbar, setDirs] = useState([])
    const dirname = directoryName(rootdir, subdir)
    const rootdirWithoutSlash = rootdir.replace(/^\/|\/$/g, '')
    const addImage = (fname) => setList([...list, image({fname}, dirname)])

    const setExtendedList = (result) => {
        console.log('orderBy:', orderBy)
        const list = result?(orderBy===ORDER_BY_MDATE_ASC)?result.map(it => image(it, dirname)).sort((b,a)=>(a.mdate===undefined)?1:(b.mdate===undefined)?-1:a.mdate.localeCompare(b.mdate))
            :(orderBy===ORDER_BY_MDATE_DESC)?result.map(it => image(it, dirname)).sort((a,b)=>(a.mdate===undefined)?1:(b.mdate===undefined)?-1:a.mdate.localeCompare(b.mdate))
            :result.map(it => image(it, dirname)).sort((a,b)=>a.fname===undefined?1:b.fname===undefined?-1:a.fname.localeCompare(b.fname))
            :[]
        setList(list) 
    }    

    useEffect(() => {
      const fetchData = async () => {
          let url=apiBaseUrl + '/listDirs?rootdir=' + rootdirWithoutSlash + (subdir?('&subdir=' + subdir):'')
          const result = await axios(url,);
          console.log('LayoutImages:url', url)  
          console.log('/listDirs: result.data', result.data)  
          const rdirs = result.data.result?result.data.result.map(dir => dir):[]  
          setDirs(rdirs)
      }  
      fetchData()
    }, [rootdir, subdir])
    
    useEffect(() => {
        const fetchData = async () => {
          let url=apiBaseUrl + '/listImagesData?rootdir=' + rootdirWithoutSlash + (subdir?('&subdir=' + subdir):'')
          console.log('apiBaseUrl:', apiBaseUrl, ' url:', url)
          const result = await axios(url,);
          setExtendedList(result.data?result.data.result?result.data.result:[]:[])
          console.log('url:', url, 'data:', result.data)
        }
        fetchData()     
    }, [rootdir, subdir, orderBy])
   

    const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {list, navbar, rootdir, subdir, setList, setExtendedList, setDirs, addImage})
)
return (
        childrenWithProps
    )  
}    


const mapStateToProps = state => ({
    rootdir:state.navbar[NAVBAR_KEY_ROOTDIR],
    subdir:state.navbar[NAVBAR_KEY_SUBDIR],
    orderBy: state.navbar[NAVBAR_KEY_ORDER_BY],
})
  
export default connect(mapStateToProps, null)(LayoutImages)
