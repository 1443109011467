import React, {useState}  from 'react'
import { connect } from 'react-redux';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Tooltip from '@material-ui/core/Tooltip';
import { setNavbarKey } from '../../state/navbar';

const butColor ='#AAA' 

const Function = ({navbar, subdir, dispatch}) => {

    const [open, setOpen] = useState(false)
    const styles = {
        button:{
            width:45, 
            height:45, 
            padding:0, 
            border:0,
            color:butColor,
        }
    }

    const handleSelect = (sub) => {
        setOpen(false)
        dispatch(setNavbarKey('subdir', sub))
    }

    return (
        <div className={open?"dropdown is-active":"dropdown"}>
            <div className="dropdown-trigger">
                <Tooltip title={'Move to a sub-folder'}>
                    {open?
                        <FolderOpenIcon 
                            className='button' 
                            style={styles.button} 
                            onClick={()=>setOpen(!open)} 
                        />
                    :
                        <FolderIcon 
                            className='button' 
                            style={styles.button} 
                            onClick={()=>setOpen(!open)} />}
                </Tooltip>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    <a href="#" 
                        className={subdir?subdir === '.'?"dropdown-item is-active":"dropdown-item":"dropdown-item"}
                        onClick={()=>handleSelect('.')}
                    >
                        Go back 
                    </a>

                    {navbar?navbar.map(di =>
                        <a href="#" 
                            className={di === subdir?"dropdown-item is-active":"dropdown-item"}
                            onClick={()=>handleSelect(di)}
                        >
                            {di}
                        </a>
                    ):null}

                </div>
            </div>
        </div>    
    )  
}   

const mapStateToProps = state => ({
    subdir:state.navbar.subdir,
})
  
export default connect(mapStateToProps, null)(Function)

