
import React, {useState}  from 'react'
import axios from 'axios'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import Tooltip from '@material-ui/core/Tooltip';
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

const butColor ='#BBB' 
 
const styles = {
    button:{width:45, height:45, padding:0, border:0}
}

const Function = ({rootdir, subdir, navbar, setDirs}) => {
  const [buttonColor, setButtonColor] = useState(butColor)
   
  const createDirectory = () => {
    setButtonColor('green')
    const folder = prompt("Please, add new subfolder:")
    if (folder === null)  {
      setButtonColor(butColor)
      return
    } else if (folder.length === 0) {
      setButtonColor(butColor)
      return
    }
    setButtonColor('yellow')
    const url = apiBaseUrl + '/createDirectory'
    const payload = { 
      rootdir,
      subdir:subdir?subdir:null,
      folder, 
    }  
    console.log('createDirectory: url:', url, ' payload:', payload)
    axios.post(url, payload).then(response => {
        setButtonColor(butColor)
        console.log('response', response)
        setDirs([...navbar, (subdir!==undefined?(subdir + '/'):'') + folder])
    }).catch(error => {
        setButtonColor('red')
        console.log('ERROR: Failed to upload:', error);
    });
  }

  return(
    <Tooltip title={'Create a new sub-folder'}>
        <CreateNewFolderIcon 
          className="column" 
          subdir={subdir} 
          onClick={createDirectory} 
          style={{...styles.button, color:buttonColor, size:'small'}}
        />
    </Tooltip>
  )
}          
// <img className='column' src={it.src} height={160} alt={it.src}/>

export default Function
    