
import React, {useState}  from 'react'
import axios from 'axios'
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';

const butColor ='#AAA' 
 
const Function = ({list, setExtendedList, rootdir, subdir}) => {
  const [buttonColor, setButtonColor] = useState(butColor)
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

  const styles = {
      button:{width:45, height:45, padding:0, border:0}
  }
  const saveOnDisk = () => {
    setButtonColor('yellow')

    if (list.filter(it=>it.delete !== undefined || it.rotate!==undefined).length > 0) {
      const files = list.filter(it => it.delete !== undefined || it.rotate !== undefined)
      console.log('files:', files)
      const payload = {
        files, 
        rootdir,
        subdir, 
      }
      axios.post(apiBaseUrl + '/removeOrRotateImages', payload,
      {
        onUploadProgress: progressEvent => {console.log(progressEvent.loaded / progressEvent.total)}
      }
      ).then(response => {
        console.log('Status code:', response.status);
        console.log('Status data:', response.data);
        setButtonColor(butColor)
        console.log('response.data', response.data)
        setExtendedList(response.data.result?response.data.result:[])
      }).catch(error => {
        console.log('ERROR: Failed to upload:', error);
        setButtonColor('red')
      });
    } else {
      setButtonColor(buttonColor)
      alert('No images marked for delete !')
    }  
  }
 
  const opacity = list?list.find(it => (it.delete !== undefined || it.rotate !== undefined))?1.0:0.3:1.0

  return(
    <Tooltip title={'Save changes (rotation or delete)'}>
      <SaveIcon 
        className="column" 
        onClick={saveOnDisk} 
        style={{...styles.button, color:buttonColor, opacity}}
      />
    </Tooltip>
  )
}          

export default Function
    