import React, {useState} from "react"

const Timeline = ({list}) => {
  const arr0 = new Array(60).fill(false)
  const [arr, setArr] = useState(arr0);
  const toggleArr = (ix) => setArr([...arr.slice(0, ix), arr[ix]===true?false:true, ...arr.slice(ix + 1)])
  console.log('arr0.length', arr0.length)
return(
<div className="timeline has-text-light">
  <header className="timeline-header">
    <span className="tag is-medium is-primary"  style={{borderRadius:32}}>Start</span>
  </header>
  <div className="timeline-item is-primary">
    <div className="timeline-marker is-primary"></div>
    <div className="timeline-content">
      <p className="heading">January 2016</p>
      <p>Timeline content - Can include any HTML element</p>
    </div>
  </div>
  {list.map((it, index)=>
    <div className="timeline-item is-primary" >
      <div className={"timeline-marker is-primary is-image is-64x64"} onClick={()=>toggleArr(index)}>
          <img src={it.thumbSrc?it.thumbSrc:it.src} style={{objectFit:'cover', height:64, width:64}} alt={it.src} />
      </div>
      <div className="timeline-content">
        <p className="heading">{it.fname}</p>
        <p  style={{fontSize:'small'}} >Här kommer text - {it.mdate}</p>
        <p style={{fontSize:'x-small'}} >Fullt filnamn: {it.src}</p>
        <p style={{fontSize:'x-small'}} >Fullt thumbnamn: {it.thumbSrc}</p>
        {<img src={it.thumbSrc?it.thumbSrc:it.src} style={{width:arr[index]?'70%':0, opacity:arr[index]?1.0:0.0, transition:'1000ms all ease'}} alt={it.src} />}
      </div>
    </div>  
  )}
  <header className="timeline-header">
    <span className="tag is-primary">2017</span>
  </header>
  <div className="timeline-item is-danger">
    <div className="timeline-marker is-danger is-icon">
      <i className="fa fa-flag"></i>
    </div>
    <div className="timeline-content">
      <p className="heading">March 2017</p>
      <p>Timeline content - Can include any HTML element</p>
    </div>
  </div>
  <header className="timeline-header">
    <span className="tag is-medium is-primary">End</span>
  </header>
</div>
)}

export default Timeline
