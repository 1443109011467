
import React from 'react'
import { connect } from 'react-redux';
//import ButtonAddPhoto from "./ButtonAddPhoto"
import ButtonAddPhotoMultiple from "./ButtonAddPhotoMultiple"
import ButtonCreateFolder from "./ButtonCreateFolder"
import ButtonSave from "./ButtonSave"
import ButtonMoveToSubdir from "./ButtonMoveToSubdir"
import ButtonThumbnail from "./ButtonThumbnail"


const Function = (props) => {
  return(
    props.edit?
      <div className = 'columns is-mobile'> 
          <div className='column is-1'>
            <ButtonAddPhotoMultiple {...props} />
          </div>
          <div className='column is-1'>
            <ButtonSave {...props} />
          </div>
          <div className='column is-1'>
            <ButtonCreateFolder {...props} />
          </div>
          <div className='column is-1'>
            <ButtonThumbnail {...props} />
          </div>
      </div>
    :
      null    
  )
}          
// <img className='column' src={it.src} height={160} alt={it.src}/>


const mapStateToProps = state => ({
  edit:state.navbar.edit,
})

export default connect(mapStateToProps, null)(Function)

    